import type { DateTime } from "@evercam/shared/types/time"

export enum WidgetType {
  Live = "live",
  Recording = "recording",
  CameraBim = "Camera BIM",
}

export const RessourceByType = {
  camera: [WidgetType.Live, WidgetType.Recording, WidgetType.CameraBim],
  project: [],
}

export type WidgetsListRequestPayload = {
  userId: number
  projectId: number
  cameraId: number
  active: boolean
  widgetType: WidgetType
  allowedOrigin: string
}

export type Widget = {
  id: number
  ressources: {
    camera?: {
      id: number
      exid: string
      name: string
    }
    project?: {
      id: number
      exid: string
      name: string
    }
  }
  widgetType: WidgetType
  active: boolean
  user: {
    id: number
    email: string
  }
  insertedAt: DateTime
  updatedAt: DateTime
}

export type WidgetFull = {
  id: number
  ressources: {
    camera?: {
      id: number
      exid: string
      name: string
    }
    project?: {
      id: number
      exid: string
      name: string
    }
  }
  widgetType: WidgetType
  widgetKey: string
  widgetId: string
  active: boolean
  user: {
    id: number
    email: string
  }
  settings: any
  allowedOrigin: string
  insertedAt: DateTime
  updatedAt: DateTime
}

export type WidgetPayload = {
  ressources: {
    cameraId?: number
    projectId?: number
  }
  widgetType: WidgetType
  active: boolean
  settings: any
  allowedOrigin: string
}
