import { type SortingUrlParam } from "@evercam/shared/types/components"
import { FeedbackType } from "@evercam/ui"

export * from "@evercam/shared/types/360"
export * from "@evercam/shared/types/aconex"
export * from "@evercam/shared/types/analytics"
export * from "@evercam/shared/types/anpr"
export * from "@evercam/shared/types/media"
export * from "@evercam/shared/types/autodesk"
export * from "@evercam/shared/types/bim"
export * from "@evercam/shared/types/camera"
export * from "@evercam/shared/types/compare"
export * from "@evercam/shared/types/components"
export * from "@evercam/shared/types/copilot"
export * from "@evercam/shared/types/credentials"
export * from "@evercam/shared/types/devices"
export * from "@evercam/shared/types/drone"
export * from "@evercam/shared/types/errors"
export * from "@evercam/shared/types/gateReport"
export * from "@evercam/shared/types/imagePlayer"
export * from "@evercam/shared/types/ingest"
export * from "@evercam/shared/types/map"
export * from "@evercam/shared/types/connector"
export * from "@evercam/shared/types/notification"
export * from "@evercam/shared/types/nvr"
export * from "@evercam/shared/types/procore"
export * from "@evercam/shared/types/progressPhoto"
export * from "@evercam/shared/types/project"
export * from "@evercam/shared/types/recording"
export * from "@evercam/shared/types/roi"
export * from "@evercam/shared/types/routeParams"
export * from "@evercam/shared/types/router"
export * from "@evercam/shared/types/shares"
export * from "@evercam/shared/types/sim"
export * from "@evercam/shared/types/sitePlanner"
export * from "@evercam/shared/types/storageServers"
export * from "@evercam/shared/types/streaming"
export * from "@evercam/shared/types/time"
export * from "@evercam/shared/types/timelapse"
export * from "@evercam/shared/types/timelapseReports"
export * from "@evercam/shared/types/user"
export * from "@evercam/shared/types/vendorModel"
export * from "@evercam/shared/types/videoWall"
export * from "@evercam/shared/types/voyageControl"
export * from "@evercam/shared/types/weather"
export * from "@evercam/shared/types/timeline"
export * from "@evercam/shared/types/comments"
export * from "@evercam/shared/types/detections"
export * from "@evercam/shared/types/widget"

export interface PaginatedItems<T> {
  from: number
  items: Array<T>
  limit: number
  page: number
  to: number
  total: number
}

export enum UnitSystem {
  Imperial = "imperial",
  Metric = "metric",
}

export type Timestamp = string | number

export type DateType = string | Date

export type Schedule = {
  Friday: string[]
  Monday: string[]
  Saturday: string[]
  Sunday: string[]
  Thursday: string[]
  Tuesday: string[]
  Wednesday: string[]
}

export type ScheduleLowercase = {
  friday: string[]
  monday: string[]
  saturday: string[]
  sunday: string[]
  thursday: string[]
  tuesday: string[]
  wednesday: string[]
}

export type PaginationParams = {
  sort?: SortingUrlParam
  page?: number
  limit?: number
}

export type LabsPaginationParams = {
  sortBy?: string
  sortDirection?: "asc" | "desc"
  page?: number
  limit?: number
}

export enum MobileDevice {
  Ios = "ios",
  Android = "android",
}

export enum _3dViewer {
  Itwin = "itwin",
  Cesium = "cesium",
}

export enum SsoProvider {
  Microsoft = "microsoft",
  Google = "google",
  Evercam = "evercam",
}

export enum InfoPage {
  ThreeSixtyView = "360",
  BIMView = "bim",
  BimCompare = "bim-compare",
  GateReport = "gate-report",
  DroneView = "drone-view",
}

export enum DownloadTypes {
  Jpeg = "jpeg",
  Pdf = "pdf",
  Csv = "csv",
}

export enum FeedbackContext {
  CopilotMessage = "copilot_message",
}

export type FeedbackPayload = {
  id?: number
  user: string
  text: string
  type: FeedbackType
  context: FeedbackContext
  messageId?: number
  conversationId?: number
}

export type ProjectPAStatsRequestPayload = {
  period: string
  startDate: string
  endDate: string
}

export type Stringified<T> = string & {
  [P in keyof T]: { "_ value": T[P] }
}
export interface JSON {
  stringify<T>(
    value: T,
    replacer?: (key: string, value: unknown) => unknown,
    space?: string | number
  ): string & Stringified<T>
  parse<T>(
    text: Stringified<T>,
    reviver?: (key: unknown, value: unknown) => unknown
  ): T
}

export type Exid = string

export type EntityByExid<Entity extends unknown> = Record<Exid, Entity>

export enum Region {
  APAC = "apac",
  AU = "au",
  SG = "sg",
  EU = "eu",
  UK = "uk",
  IE = "ie",
  US = "us",
}
